.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  overflow-x: hidden;
}

a {
  color: white;
  text-decoration: none;
}

.container-title {
  text-align: center;
  margin-bottom: 50px !important;
}

.profile-field {
  margin-bottom: 25px !important;
}

.MuiDialog-paper {
  background-image: rgba(255, 255, 255, 0.05);
  background-color: #25164f !important;
  border-radius: 25px;
}

.benefit:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.card-box {
  transition: all 0.2s ease-in-out !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-image: rgba(255, 255, 255, 0.05);
}

.card-box a {
  width: 80%;
}

.card-box:hover {
  transform: scale(1.05);
}

.MuiTableRow-head {
  background-color: rgba(255, 255, 255, 0.11);
}

.MuiTableContainer-root {
  transition: all 0.2s ease-in-out !important;
  background-color: #25164f !important;
}

.MuiTableContainer-root:hover {
  transform: scale(1.01);
}

.step-icon {
  width: 80px !important;
  height: 80px !important;
}

.how-step {
  text-align: center;
  transition: all 0.2s ease-in-out !important;
}

.how-step:hover {
  transform: scale(1.05);
}

.gradient-button {
  color: white !important;
  background: linear-gradient(45deg, #7c00ec, #d800d8);
}

.gradient-button:hover,
.gradient-button:focus {
  transition: ease-out 1s;
  background: #e91e63 !important;
}

.flex {
  display: flex;
}

.nav-spacer {
  display: flex;
  flex: 1;
}